
import {radioPlayerMixin} from '~/plugins/mixins/radio.js';

export default {
  mixins: [radioPlayerMixin],
  computed: {
    stationToShow() {
      return this.currentPlayingStation ?? this.station;
    },
  },
};
