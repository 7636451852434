export default ({req, redirect}) => {
  let userAgent = null;
  let url = null;

  if (typeof req !== 'undefined') {
    userAgent = req.headers['user-agent'];
    url = req.url;
  } else if (typeof navigator !== 'undefined') {
    userAgent = navigator.userAgent;
    url = window.location.pathname + window.location.search;
  }

  if (/SamsungBrowser\//i.test(userAgent) && url) {
    const path = url.replace(/^\/+/g, '');

    redirect(`intent://${path}/#Intent;scheme=ro;package=nl.reformatorischeomroep;end`);
  }
};
