import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f0a08468 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _630a41e4 = () => interopDefault(import('../pages/concertagenda/index.vue' /* webpackChunkName: "pages/concertagenda/index" */))
const _252ce04f = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _bd131b60 = () => interopDefault(import('../pages/doneer.vue' /* webpackChunkName: "pages/doneer" */))
const _549a947e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f0bd8076 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _5629e325 = () => interopDefault(import('../pages/form.vue' /* webpackChunkName: "pages/form" */))
const _6c8e2991 = () => interopDefault(import('../pages/gift.vue' /* webpackChunkName: "pages/gift" */))
const _1e6cceeb = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _ceb41926 = () => interopDefault(import('../pages/radio/index.vue' /* webpackChunkName: "pages/radio/index" */))
const _1f895826 = () => interopDefault(import('../pages/video/index.vue' /* webpackChunkName: "pages/video/index" */))
const _36089ca5 = () => interopDefault(import('../pages/zoeken.vue' /* webpackChunkName: "pages/zoeken" */))
const _48a042d1 = () => interopDefault(import('../pages/auth/profiel.vue' /* webpackChunkName: "pages/auth/profiel" */))
const _6bee8ccf = () => interopDefault(import('../pages/auth/registreren/index.vue' /* webpackChunkName: "pages/auth/registreren/index" */))
const _22c69af5 = () => interopDefault(import('../pages/auth/verifieren.vue' /* webpackChunkName: "pages/auth/verifieren" */))
const _6d741522 = () => interopDefault(import('../pages/auth/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/auth/wachtwoord-vergeten/index" */))
const _464ec8db = () => interopDefault(import('../pages/auth/registreren/gratis.vue' /* webpackChunkName: "pages/auth/registreren/gratis" */))
const _b3f68a6e = () => interopDefault(import('../pages/auth/wachtwoord-vergeten/token.vue' /* webpackChunkName: "pages/auth/wachtwoord-vergeten/token" */))
const _1cb62da3 = () => interopDefault(import('../pages/nieuws/_slug.vue' /* webpackChunkName: "pages/nieuws/_slug" */))
const _40862e30 = () => interopDefault(import('../pages/radio/_slug/index.vue' /* webpackChunkName: "pages/radio/_slug/index" */))
const _3ce7e968 = () => interopDefault(import('../pages/video/_slug/index.vue' /* webpackChunkName: "pages/video/_slug/index" */))
const _475e85c8 = () => interopDefault(import('../pages/radio/_slug/_broadcast.vue' /* webpackChunkName: "pages/radio/_slug/_broadcast" */))
const _2ce124d6 = () => interopDefault(import('../pages/video/_slug/_video.vue' /* webpackChunkName: "pages/video/_slug/_video" */))
const _0c5d36ce = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _f0a08468,
    pathToRegexpOptions: {"strict":true},
    name: "auth___nl"
  }, {
    path: "/concertagenda",
    component: _630a41e4,
    pathToRegexpOptions: {"strict":true},
    name: "concertagenda___nl"
  }, {
    path: "/contact",
    component: _252ce04f,
    pathToRegexpOptions: {"strict":true},
    name: "contact___nl"
  }, {
    path: "/doneer",
    component: _bd131b60,
    pathToRegexpOptions: {"strict":true},
    name: "doneer___nl"
  }, {
    path: "/en",
    component: _549a947e,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/faq",
    component: _f0bd8076,
    pathToRegexpOptions: {"strict":true},
    name: "faq___nl"
  }, {
    path: "/form",
    component: _5629e325,
    pathToRegexpOptions: {"strict":true},
    name: "form___nl"
  }, {
    path: "/gift",
    component: _6c8e2991,
    pathToRegexpOptions: {"strict":true},
    name: "gift___nl"
  }, {
    path: "/nieuws",
    component: _1e6cceeb,
    pathToRegexpOptions: {"strict":true},
    name: "nieuws___nl"
  }, {
    path: "/radio",
    component: _ceb41926,
    pathToRegexpOptions: {"strict":true},
    name: "radio___nl"
  }, {
    path: "/video",
    component: _1f895826,
    pathToRegexpOptions: {"strict":true},
    name: "video___nl"
  }, {
    path: "/zoeken",
    component: _36089ca5,
    pathToRegexpOptions: {"strict":true},
    name: "zoeken___nl"
  }, {
    path: "/auth/profiel",
    component: _48a042d1,
    pathToRegexpOptions: {"strict":true},
    name: "auth-profiel___nl"
  }, {
    path: "/auth/registreren",
    component: _6bee8ccf,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registreren___nl"
  }, {
    path: "/auth/verifieren",
    component: _22c69af5,
    pathToRegexpOptions: {"strict":true},
    name: "auth-verifieren___nl"
  }, {
    path: "/auth/wachtwoord-vergeten",
    component: _6d741522,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten___nl"
  }, {
    path: "/en/auth",
    component: _f0a08468,
    pathToRegexpOptions: {"strict":true},
    name: "auth___en"
  }, {
    path: "/en/concertagenda",
    component: _630a41e4,
    pathToRegexpOptions: {"strict":true},
    name: "concertagenda___en"
  }, {
    path: "/en/contact",
    component: _252ce04f,
    pathToRegexpOptions: {"strict":true},
    name: "contact___en"
  }, {
    path: "/en/doneer",
    component: _bd131b60,
    pathToRegexpOptions: {"strict":true},
    name: "doneer___en"
  }, {
    path: "/en/faq",
    component: _f0bd8076,
    pathToRegexpOptions: {"strict":true},
    name: "faq___en"
  }, {
    path: "/en/form",
    component: _5629e325,
    pathToRegexpOptions: {"strict":true},
    name: "form___en"
  }, {
    path: "/en/gift",
    component: _6c8e2991,
    pathToRegexpOptions: {"strict":true},
    name: "gift___en"
  }, {
    path: "/en/nieuws",
    component: _1e6cceeb,
    pathToRegexpOptions: {"strict":true},
    name: "nieuws___en"
  }, {
    path: "/en/radio",
    component: _ceb41926,
    pathToRegexpOptions: {"strict":true},
    name: "radio___en"
  }, {
    path: "/en/video",
    component: _1f895826,
    pathToRegexpOptions: {"strict":true},
    name: "video___en"
  }, {
    path: "/en/zoeken",
    component: _36089ca5,
    pathToRegexpOptions: {"strict":true},
    name: "zoeken___en"
  }, {
    path: "/auth/registreren/gratis",
    component: _464ec8db,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registreren-gratis___nl"
  }, {
    path: "/auth/wachtwoord-vergeten/token",
    component: _b3f68a6e,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten-token___nl"
  }, {
    path: "/en/auth/profiel",
    component: _48a042d1,
    pathToRegexpOptions: {"strict":true},
    name: "auth-profiel___en"
  }, {
    path: "/en/auth/registreren",
    component: _6bee8ccf,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registreren___en"
  }, {
    path: "/en/auth/verifieren",
    component: _22c69af5,
    pathToRegexpOptions: {"strict":true},
    name: "auth-verifieren___en"
  }, {
    path: "/en/auth/wachtwoord-vergeten",
    component: _6d741522,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten___en"
  }, {
    path: "/en/auth/registreren/gratis",
    component: _464ec8db,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registreren-gratis___en"
  }, {
    path: "/en/auth/wachtwoord-vergeten/token",
    component: _b3f68a6e,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten-token___en"
  }, {
    path: "/en/nieuws/:slug",
    component: _1cb62da3,
    pathToRegexpOptions: {"strict":true},
    name: "nieuws-slug___en"
  }, {
    path: "/en/radio/:slug",
    component: _40862e30,
    pathToRegexpOptions: {"strict":true},
    name: "radio-slug___en"
  }, {
    path: "/en/video/:slug",
    component: _3ce7e968,
    pathToRegexpOptions: {"strict":true},
    name: "video-slug___en"
  }, {
    path: "/en/radio/:slug/:broadcast",
    component: _475e85c8,
    pathToRegexpOptions: {"strict":true},
    name: "radio-slug-broadcast___en"
  }, {
    path: "/en/video/:slug/:video",
    component: _2ce124d6,
    pathToRegexpOptions: {"strict":true},
    name: "video-slug-video___en"
  }, {
    path: "/nieuws/:slug",
    component: _1cb62da3,
    pathToRegexpOptions: {"strict":true},
    name: "nieuws-slug___nl"
  }, {
    path: "/radio/:slug",
    component: _40862e30,
    pathToRegexpOptions: {"strict":true},
    name: "radio-slug___nl"
  }, {
    path: "/video/:slug",
    component: _3ce7e968,
    pathToRegexpOptions: {"strict":true},
    name: "video-slug___nl"
  }, {
    path: "/radio/:slug/:broadcast",
    component: _475e85c8,
    pathToRegexpOptions: {"strict":true},
    name: "radio-slug-broadcast___nl"
  }, {
    path: "/video/:slug/:video",
    component: _2ce124d6,
    pathToRegexpOptions: {"strict":true},
    name: "video-slug-video___nl"
  }, {
    path: "/en/*",
    component: _0c5d36ce,
    pathToRegexpOptions: {"strict":true},
    name: "*___en"
  }, {
    path: "/",
    component: _549a947e,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl"
  }, {
    path: "/*",
    component: _0c5d36ce,
    pathToRegexpOptions: {"strict":true},
    name: "*___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
