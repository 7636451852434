
export default {
  props: {
    song: {
      type: Object,
      required: true,
    },
    timestamp: {
      type: String,
      required: true,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
};
