
export default {
  props: {
    station: {
      type: Object,
      required: true,
    },
    currentSong: {
      type: Object,
      required: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
};
