
import {Capacitor} from '@capacitor/core';
import {radioProviderMixin, radioPlayerMixin} from '~/plugins/mixins/radio.js';

export default {
  mixins: [radioProviderMixin, radioPlayerMixin],
  data: () => ({
    mobileMenuState: 0,
  }),
  computed: {
    isNativePlatform() {
      return Capacitor.isNativePlatform();
    },
    radioOverlaysOpen() {
      return this.mobileMenuState === 2 || this.mobileMenuState === 3;
    },
  },
  watch: {
    $route() {
      this.mobileMenuState = 0;
    },
  },
};
