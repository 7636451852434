
export default {
  props: {
    currentSong: {
      type: Object,
      required: true,
    },
    futureSongs: {
      type: Array,
      required: true,
    },
    pastSongs: {
      type: Array,
      required: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showHistory: false,
  }),
};
