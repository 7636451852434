import pageQuery from '~/graphql/queries/sofie/page.gql';
import menusQuery from '~/graphql/queries/sofie/menus.gql';
import settingsQuery from '~/graphql/queries/sofie/settings.gql';

export const state = () => ({
  previousRoute: null,
  hasAppOpenListener: false,
  release: process.env.RELEASE,
  settings: [],
  page: {seo: {}, template: {}},
  menuMain: {},
  menuSidebar: {},
  menuFooter: {},
});

const getMenuItems = (menus, name) => {
  const menu = menus.find(m => m.name === name);

  if (!menu) {
    return [];
  }

  return menu.items;
};

export const actions = {
  async nuxtServerInit({dispatch}) {
    try {
      await dispatch('initialLoad');
    } catch (e) {
      console.error(e);
    }
  },

  async initialLoad({commit}) {
    const [menus, settings] = await Promise.all([
      this.app.apolloProvider.defaultClient.query({
        query: menusQuery,
        variables: {
          names: ['main', 'footer', 'sidebar'],
        },
      }),
      this.app.apolloProvider.defaultClient.query({
        query: settingsQuery,
      }),
    ]);

    commit('SET_SETTINGS', settings.data?.settings || []);
    commit('SET_MENU_MAIN', getMenuItems(menus.data.menus, 'main'));
    commit('SET_MENU_FOOTER', getMenuItems(menus.data.menus, 'footer'));
    commit('SET_MENU_SIDEBAR', getMenuItems(menus.data.menus, 'sidebar'));
  },

  async getPage({commit}, slug = 'home') {
    const {
      data: {page},
    } = await this.app.apolloProvider.defaultClient.query({
      query: pageQuery,
      variables: {
        segments: slug === '' ? 'home' : slug,
      },
    });

    if (!page) {
      const e = new Error('Page not found');
      e.statusCode = 410;
      throw e;
    }

    commit('SET_PAGE', {
      ...page,
      ...page.template,
    });
  },
};

export const mutations = {
  SET_PREVIOUS_ROUTE(state, payload) {
    state.previousRoute = payload;
  },
  SET_HAS_APP_OPEN_LISTENER(state, payload) {
    state.hasAppOpenListener = payload;
  },
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_MENU_MAIN(state, payload) {
    state.menuMain = payload;
  },
  SET_MENU_FOOTER(state, payload) {
    state.menuFooter = payload;
  },
  SET_MENU_SIDEBAR(state, payload) {
    state.menuSidebar = payload;
  },
};

export const getters = {
  getPreviousRoute(state) {
    return state.previousRoute;
  },
  getHasAppOpenListener(state) {
    return state.hasAppOpenListener;
  },
  getFromSettings: state => (key) => {
    return state.settings.find(s => s.key === key)?.value;
  },
  isSubscriber: (state) => {
    return state.auth.loggedIn && state.auth.user?.membership?.length;
  },
};
