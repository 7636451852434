
export default {
  props: {
    advertisement: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getAdvertisement(advertisement) {
      this.$gtag('event', 'track_advertisement', {title: advertisement.title, url: advertisement.url});
    },
  },
};
