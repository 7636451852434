
import {mapGetters} from 'vuex';

export default {
  methods: {
    trackGiftButtonInfo() {
      this.$gtag('event', 'view_giftform');
    },
  },
  computed: {
    ...mapGetters([
      'getFromSettings',
      'isSubscriber',
    ]),
    facebook() {
      return this.getFromSettings('facebook');
    },
    linkedin() {
      return this.getFromSettings('linkedin');
    },
    instagram() {
      return this.getFromSettings('instagram');
    },
    hasAndroidOrIOSLink() {
      return !!(this.getFromSettings('android') || this.getFromSettings('ios'));
    },
  },
};
