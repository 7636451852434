import Hls from 'hls.js';

export default ({app}, inject) => {
  let hls = null;

  inject('hls', {
    create() {
      hls = new Hls();

      return hls;
    },
    getHls() {
      return hls;
    },
    destroy() {
      hls.detachMedia();
      hls.destroy();

      hls = null;
    },
  });
};
