import { render, staticRenderFns } from "./MobileNavigation.vue?vue&type=template&id=5d18d2e8&scoped=true&"
import script from "./MobileNavigation.vue?vue&type=script&lang=js&"
export * from "./MobileNavigation.vue?vue&type=script&lang=js&"
import style0 from "./MobileNavigation.vue?vue&type=style&index=0&id=5d18d2e8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d18d2e8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsPerson: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Person.vue').default,IconsLogout: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Logout.vue').default,IconsLogoEmblemFull: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/LogoEmblemFull.vue').default,IconsHome: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Home.vue').default,IconsHeadphone: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Headphone.vue').default,IconsVideo: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Video.vue').default,IconsConcert: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Concert.vue').default,IconsNewspaper: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Newspaper.vue').default,IconsQuestionmark: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Questionmark.vue').default,IconsPhone: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Phone.vue').default,IconsArrowRight: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/ArrowRight.vue').default,RadioStation: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/radio/Station.vue').default,RadioPlayer: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/radio/Player.vue').default,IconsDoubleChevronRight: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/DoubleChevronRight.vue').default,RadioPlaylist: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/radio/Playlist.vue').default})
