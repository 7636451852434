import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=0069a445&scoped=true&"
import script from "./Navigation.vue?vue&type=script&lang=js&"
export * from "./Navigation.vue?vue&type=script&lang=js&"
import style0 from "./Navigation.vue?vue&type=style&index=0&id=0069a445&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0069a445",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsLogoDesktop: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/LogoDesktop.vue').default,IconsMagnifyingGlass: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/MagnifyingGlass.vue').default,IconsPerson: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Person.vue').default,IconsHamburgerMenu: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/HamburgerMenu.vue').default,IconsHeadphone: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Headphone.vue').default,IconsCamera: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Camera.vue').default,IconsChoir: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Choir.vue').default,IconsNewspaper: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Newspaper.vue').default,DownloadLinks: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/base/DownloadLinks.vue').default,LayoutsMobileNavigation: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/layouts/MobileNavigation.vue').default})
