import {utcToZonedTime} from 'date-fns-tz';
import {mapGetters} from 'vuex';
import {NativeAudio} from '@bttr-devs/native-audio';
import Hls from 'hls.js';
import stationsQuery from '~/graphql/queries/radio/stations.gql';
import playlistsQuery from '~/graphql/queries/radio/playlists.gql';

export const radioProviderMixin = {
  apollo: {
    stations: {
      query: stationsQuery,
      fetchPolicy: 'no-cache',
      result({data: {stations}}) {
        this.$store.dispatch('radio/setAllStations', stations);
      },
      skip: !process.client,
    },
    playlists: {
      query: playlistsQuery,
      update: data => data?.playlists || [],
      skip: !process.client,
      pollInterval: 300000, // 5 minutes
    },
  },
  data: () => ({
    activeStationId: null,
    stations: [],
    playlists: [],
    now: Date.now(),
    timer: null,
  }),
  computed: {
    ...mapGetters({
      currentPlayingStation: 'radio/currentPlayingStation',
    }),
    activeStation() {
      if (this.stations.length && !this.activeStationId) {
        return this.stations[0];
      }

      return this.stations.find(s => s.id === this.activeStationId);
    },
    userTimeZone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    stationSongs() {
      return this.playlists.map(station => ({
        station: station.id,
        songs: station.playlist.map(song => ({
          id: song.id,
          title: song.title,
          author: song.author ?? '',
          start: utcToZonedTime(song.start, this.userTimeZone),
          end: utcToZonedTime(song.end, this.userTimeZone),
        })),
      }));
    },
    songDataForCurrentStation() {
      const station = this.currentPlayingStation || this.activeStation;

      return this.songDataForStation(station);
    },
    currentPlayingSong() {
      return this.stationSongs.map(({station, songs}) => {
        const matches = songs.filter(song => this.now >= song.start.getTime() && this.now <= song.end.getTime());

        return {
          station,
          current: matches.length ? matches.shift() : {},
        };
      });
    },
    futureSongs() {
      return this.stationSongs.map(({station, songs}) => {
        const matches = songs.filter(song => this.now < song.start.getTime());

        return {
          station,
          songs: matches.length ? [matches.pop()] : [],
        };
      });
    },
    pastSongs() {
      return this.stationSongs.map(({station, songs}) => {
        return {
          station,
          past: songs.filter(song => this.now > song.end.getTime()),
        };
      });
    },
  },
  methods: {
    songDataForStation(station) {
      return {
        current: station ? this.currentPlayingSong.find(s => s.station === station.id).current : {},
        future: station ? this.futureSongs.find(s => s.station === station.id).songs : [],
        past: station ? this.pastSongs.find(s => s.station === station.id).past : [],
      };
    },
    currentSongForStation(stationId) {
      return this.currentPlayingSong.find(s => s.station === stationId)?.current || {};
    },
    changeStation(stationId) {
      if (this.activeStationId === stationId) {
        this.$apollo.queries.stations.refetch({id: stationId});
      } else {
        this.activeStationId = stationId;
      }
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.now = Date.now();
    }, 5000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};

export const radioPlayerMixin = {
  props: {
    station: {
      type: Object,
      default: () => ({}),
    },
    currentSong: {
      type: Object,
      default: () => ({}),
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    hls: null,
    preparedStations: [],
  }),
  computed: {
    ...mapGetters({
      currentPlayingStation: 'radio/currentPlayingStation',
      nativeAudioListenerRegistered: 'radio/nativeAudioListenerRegistered',
      allStations: 'radio/allStations',
    }),
    radioPlayer() {
      return process.client ? document.querySelector('#radio-player') : null;
    },
  },
  watch: {
    station() {
      if (this.currentPlayingStation) {
        this.pause();
        this.play(this.station);
      }
    },
  },
  methods: {
    async play(station) {
      if (!station.stream_url_app) {
        return;
      }

      if (this.currentPlayingStation) {
        if (station.id === this.currentPlayingStation.id) {
          return;
        }

        this.pause();
      }

      if (this.isNativePlatform) {
        const assetId = `ro-${station.id}`;
        await this.preloadRadioIfNecessary(assetId, station).then(async () => {
          NativeAudio.play({assetId});

          await this.prepareNativeMusicControls(assetId, station);
        }).catch((error) => {
          console.error(error);
        });
      } else if (Hls.isSupported()) {
        const hls = this.$hls.create();
        hls.loadSource(station.stream_url_app);
        hls.attachMedia(this.radioPlayer);
        hls.on(Hls.Events.MEDIA_ATTACHED, () => this.radioPlayer.play());
      } else if (this.radioPlayer.canPlayType('application/vnd.apple.mpegurl')) {
        this.radioPlayer.src = station.stream_url_app;
        this.radioPlayer.play();
      }

      this.$gtag('event', 'start_radio', {title: this.station.title});
      await this.$store.dispatch('radio/setCurrentPlayingStation', {...station});
    },
    async pause(fromListener = false) {
      this.radioPlayer.pause();

      if (this.isNativePlatform) {
        const assetId = `ro-${this.currentPlayingStation.id}`;

        const nativeState = await NativeAudio.isAssetReady({assetId});

        if (nativeState.assetState !== 0) {
          if (fromListener) {
            await NativeAudio.stop({assetId});
          }

          await NativeAudio.unload({assetId});
        }
      }

      if (Hls.isSupported() && this.$hls.getHls()) {
        this.$hls.destroy();
      }

      this.$gtag('event', 'stop_radio', {title: this.station.title});
      this.$store.dispatch('radio/setCurrentPlayingStation', null);
    },
    async prepareNativeMusicControls(assetId, station) {
      if (!this.nativeAudioListenerRegistered) {
        NativeAudio.addListener('action', (data) => {
          if (data.action === 'pause') {
            this.pause(true);
          }

          if (data.action === 'play') {
            const station = this.allStations.find(s => s.id === +(data.assetId.split('-')[1]));

            this.play(station);
          }
        });

        await this.$store.dispatch('radio/setAudioListenerRegistered', true);
      }

      await NativeAudio.setupCommandCenterControls({
        assetId,
        sessionTag: 'ro-media-session',
        notificationChannelId: 'ro-notification-session',
        notificationChannelName: 'Radio notificaties',
        notificationChannelDescription: 'Notificaties voor het tonen van de media controls van de radio',
        notificationId: 8742, // Some random ID
      });

      await NativeAudio.setupNowPlaying({
        assetId,
        title: station.title,
        artist: 'Reformatorische Omroep',
        image: station.image_mobile[0]?.url ?? '',
      });
    },
    async preloadRadioIfNecessary(assetId, station) {
      const hasNativePlayerReady = await NativeAudio.isAssetReady({assetId});
      const validAssetStates = [NativeAudio.ASSET_STATES.PREPARED, NativeAudio.ASSET_STATES.PENDING_PLAY];

      // State 1 = prepared and 2 = waiting to play. Android can send both while iOS only sends 2.
      if (validAssetStates.includes(hasNativePlayerReady.assetState)) {
        return new Promise(resolve => resolve());
      }

      await NativeAudio.preload({
        assetId,
        assetPath: station.stream_url_app,
        audioChannelNum: 1,
        isUrl: true,
      });

      const waitUntilReady = async (assetId, count, resolve, reject) => {
        // Sleep for 100ms to act as a timeout.
        // eslint-disable-next-line promise/param-names
        await new Promise(resolveTimeout => setTimeout(resolveTimeout, 100));
        const result = await NativeAudio.isAssetReady({assetId});

        if (count > 50) {
          return reject('assetReady check timeout. Reached 5000ms');
        }

        if (result.assetState !== 0) {
          return resolve();
        }

        await waitUntilReady(assetId, ++count, resolve, reject);
      };

      return new Promise((resolve, reject) => {
        waitUntilReady(assetId, 0, resolve, reject);
      });
    },
  },
};
