export const state = () => ({
  allStations: [],
  currentPlayingStation: null,
  nativeAudioListenerRegistered: false,
});

export const getters = {
  currentPlayingStation(state) {
    return state.currentPlayingStation;
  },
  nativeAudioListenerRegistered(state) {
    return state.nativeAudioListenerRegistered;
  },
  allStations(state) {
    return state.allStations;
  },
};

export const mutations = {
  SET_CURRENT_PLAYING_STATION(state, currentPlayingStation) {
    state.currentPlayingStation = currentPlayingStation;
  },
  SET_AUDIO_LISTENER_REGISTERED(state, value) {
    state.nativeAudioListenerRegistered = value;
  },
  SET_ALL_STATIONS(state, stations) {
    state.allStations = stations;
  },
};

export const actions = {
  setCurrentPlayingStation({commit}, currentPlayingStation) {
    commit('SET_CURRENT_PLAYING_STATION', currentPlayingStation);
  },
  setAudioListenerRegistered({commit}, value) {
    commit('SET_AUDIO_LISTENER_REGISTERED', value);
  },
  setAllStations({commit}, stations) {
    commit('SET_ALL_STATIONS', stations);
  },
};
