import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=56658766&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=56658766&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56658766",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsLogoDesktop: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/LogoDesktop.vue').default,IconsCamera: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Camera.vue').default,IconsHeadphone: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Headphone.vue').default,IconsChoir: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Choir.vue').default,IconsNewspaper: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Newspaper.vue').default,IconsFacebookOrange: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/FacebookOrange.vue').default,IconsLinkedin: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Linkedin.vue').default,IconsInstagram: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Instagram.vue').default,DownloadLinks: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/base/DownloadLinks.vue').default})
