import {onError} from '@apollo/client/link/error';
import {Capacitor} from '@capacitor/core';
import {Preferences} from '@capacitor/preferences';

export default function ({app, req, error: nuxtError}) {
  // Try to include the cookie package and throw an error if it isn't installed
  try {
    require('cookie-universal-nuxt');
  } catch (e) {
    e.message = 'Missing cookie-universal-nuxt.';

    throw e;
  }

  // Handle errors thrown in GraphQL response
  const errorLink = onError(({graphQLErrors, response}) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        if (error.extensions.category === 'authorization') {
          nuxtError({statusCode: 403, message: error.message});
          if (error.extensions.policy) {
            const {policy, method} = error.extensions.policy;
            this.$toast.error(`Forbidden: ${policy}::${method}`);
          }
          response.errors = null;
        }

        if (error.message === 'validation') {
          app.store.dispatch('validation/setErrors', error.extensions.validation);
        }
      });
    }
  });

  // GraphQL link handler
  const linkOptions = {
    uri: process.env.GRAPHQL_URL,
    fetch: async (uri, options) => {
      app.store.dispatch('validation/clearErrors');
      let token;

      // Add authorization token
      if (Capacitor.isNativePlatform()) {
        const preference = await Preferences.get({key: 'auth_token.apollo'});
        token = preference.value;
      } else {
        token = app.$cookies.get('auth_token.apollo');
      }

      if (token) {
        options.headers.authorization = token;
      }

      if (process.server) {
        options.headers['x-forwarded-for'] = req.connection.remoteAddress || req.socket.remoteAddress;
      }

      return fetch(uri, options);
    },
  };

  return {
    link: errorLink,
    httpLinkOptions: linkOptions,
    batching: true,
  };
}
