
import {mapGetters} from 'vuex';
import {radioPlayerMixin} from '~/plugins/mixins/radio.js';

export default {
  mixins: [radioPlayerMixin],
  props: {
    mobileMenuState: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters([
      'isSubscriber',
    ]),
    currentStationIsPlaying() {
      return this.currentPlayingStation && this.currentPlayingStation.id === this.station.id;
    },
  },
  methods: {
    handleClickedButton() {
      if (this.station.alleen_ingelogd) {
        if (!this.$auth.loggedIn) {
          this.$emit('update:mobileMenuState', 0);
          window.location.href = this.$config.authUrl;
          return;
        }

        if (this.station.met_lidmaatschap && !this.isSubscriber) {
          this.$emit('update:mobileMenuState', 0);
          return this.$router.push(this.localeRoute({name: 'doneer'}));
        }
      }

      if (!this.currentStationIsPlaying) {
        this.play(this.station);
      } else {
        this.pause();
      }
    },
  },
};
