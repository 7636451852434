import {App} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';
import {Preferences} from '@capacitor/preferences';
import {ScreenOrientation} from '@capacitor/screen-orientation';
import {mapGetters} from 'vuex';
import Vue from 'vue';

const capacitorMixin = {
  computed: {
    ...mapGetters(['getHasAppOpenListener']),
    isNativePlatform() {
      return process.client ? Capacitor.isNativePlatform() : false;
    },
  },
  async mounted() {
    if (!this.isNativePlatform || this.getHasAppOpenListener) {
      return;
    }

    await ScreenOrientation.lock({
      orientation: 'portrait',
    });

    if (this.isNativePlatform && process.client && process.env.NODE_ENV !== 'production') {
      window.preferences = Preferences;
    }

    // To prevent the creation of a lot of event listeners even when it fails.
    await this.$store.commit('SET_HAS_APP_OPEN_LISTENER', true);
    try {
      await App.addListener('appUrlOpen', (event) => {
        const routeList = this.$router.options.routes;
        const parsedUrl = new URL(event.url.replace(/\/$/, ''));
        const pathName = parsedUrl.pathname.replace('//', '/');

        if (!routeList.find(r => r.path === pathName)) {
          console.error('Tried to deeplink unknown route', pathName);
        }

        this.$router.push(this.localeRoute({
          path: pathName,
          query: Object.fromEntries(parsedUrl.searchParams),
        }));
      });
    } catch (e) {
      console.error(e);
    }
  },
};

Vue.mixin(capacitorMixin);
