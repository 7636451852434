
import {mapGetters} from 'vuex';

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    showMenu: false,
  }),
  computed: {
    ...mapGetters([
      'isSubscriber',
    ]),
    mobileMenuState: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    $route() {
      this.showMenu = false;
    },
  },
  methods: {
    hideMenu() {
      if (this.showMenu) {
        this.showMenu = false;
      }
    },
    trackGiftButtonInfo() {
      this.$gtag('event', 'view_giftform');
    },
  },
};
