import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=3cd56029&scoped=true&"
import script from "./Sidebar.vue?vue&type=script&lang=js&"
export * from "./Sidebar.vue?vue&type=script&lang=js&"
import style0 from "./Sidebar.vue?vue&type=style&index=0&id=3cd56029&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cd56029",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RadioPlayer: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/radio/Player.vue').default,RadioPlaylist: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/radio/Playlist.vue').default,RadioStation: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/radio/Station.vue').default,Advertisement: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/base/Advertisement.vue').default})
