
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    date: null,
    modelConfig: {
      mask: 'DD-MM-YYYY',
    },
    attrs: [
      {
        highlight: {
          class: 'today',
        },
        popover: {
          visibility: 'focus',
          labelClass: 'bg-white',
        },
      },
    ],
  }),
  computed: {
    pickerAttributes() {
      return Object.assign(this.context.attributes, {id: null, class: null});
    },
    isDisabled() {
      return !!this.context.attributes?.disabled;
    },
  },
  watch: {
    date(newDate) {
      try {
        this.context.model = this.$dateFns.format(newDate, 'yyyy-MM-dd');
      } catch (err) {
        //
      }
    },
    'context.model'(newValue) {
      this.date = new Date(newValue);
    },
  },
  mounted() {
    if (this.context.model) {
      this.date = this.context.model;
    }

    this.context.model = this.date;
  },
  methods: {
    parseDate(value) {
      return value;
    },
    removePadding(classes) {
      return (classes || '').split(' ').filter(c => !(c.startsWith('px') || c.startsWith('py'))).join(' ');
    },
  },
};
