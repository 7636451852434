
import {mapGetters} from 'vuex';
import {radioProviderMixin} from '~/plugins/mixins/radio.js';
import advertisementQuery from '~/graphql/queries/advertisement.gql';

export default {
  mixins: [radioProviderMixin],
  apollo: {
    advertisement: {
      query: advertisementQuery,
    },
  },
  data: () => ({
    activeTab: 1,
    showSidebar: true,
    advertisement: {},
  }),
  computed: {
    ...mapGetters([
      'isSubscriber',
    ]),
  },
  methods: {
    changeTab(tabId) {
      this.activeTab = tabId;
    },
    handleClickChangedStation(station) {
      if (station.alleen_ingelogd) {
        if (!this.$auth.loggedIn) {
          window.location.href = this.$config.authUrl;
          return;
        }

        if (station.met_lidmaatschap && !this.isSubscriber) {
          return this.$router.push(this.localeRoute({name: 'doneer'}));
        }
      }

      this.changeStation(station.id);
      this.changeTab(1);
    },
  },
};
