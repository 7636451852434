
import {App} from '@capacitor/app';
import {mapGetters} from 'vuex';
import Swiper from 'swiper/swiper-bundle.min';
import 'swiper/swiper-bundle.min.css';
import {radioProviderMixin} from '~/plugins/mixins/radio.js';

export default {
  mixins: [radioProviderMixin],
  props: {
    mobileMenuState: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    swiperInstance: null,
    activeRadioSlide: 0,
    versionString: 'WEB',
  }),
  computed: {
    ...mapGetters([
      'isSubscriber',
    ]),
    songDataForShownStation() {
      return this.songDataForStation(this.stations[this.activeRadioSlide]);
    },
  },
  watch: {
    $route() {
      this.mobileMenuState = 0;
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.swiperInstance = new Swiper(this.$refs.swiper, {
        threshold: 10,
        navigation: {
          nextEl: '.button-next',
          prevEl: '.button-prev',
          el: '.swiper-pagination',
        },
      });

      this.swiperInstance.on('slideChange', () => {
        this.activeRadioSlide = this.swiperInstance.activeIndex;
      });
    });

    if (this.isNativePlatform) {
      const {version, build} = await App.getInfo();

      this.versionString = `${version} (${build})`;
    }
  },
  methods: {
    showRadioInformation(index) {
      const station = this.stations[index];

      if (station.alleen_ingelogd) {
        if (!this.$auth.loggedIn) {
          this.$emit('update:mobileMenuState', 0);
          window.location.href = this.$config.authUrl;
          return;
        }

        if (station.met_lidmaatschap && !this.isSubscriber) {
          this.$emit('update:mobileMenuState', 0);
          return this.$router.push(this.localeRoute({name: 'doneer'}));
        }
      }

      this.$emit('update:mobileMenuState', 3);
      this.swiperInstance.slideTo(index, 0);
    },
  },
};
