
import {mapState} from 'vuex';

export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  head() {
    return {
      title: this.error.statusCode === 503 ? 'Onderhoudsmodus' : (this.page.seo?.meta_title || this.page?.title),
    };
  },
  computed: {
    ...mapState(['page']),
  },
  async created() {
    if (this.error.statusCode === 410 || this.error.statusCode === 404) {
      await this.$store.dispatch('getPage', '404');
    }
  },
};

