import Vue from 'vue';
import {nl} from '@braid/vue-formulate-i18n';
import errorHandler from '~/libs/form-error-handler';

import Button from '~/components/formulate/Button';
import Date from '~/components/formulate/Date';
import Datepicker from '~/components/formulate/Calendar';
import Toggle from '~/components/formulate/Toggle';

Vue.component('Button', Button);
Vue.component('Date', Date);
Vue.component('Datepicker', Datepicker);
Vue.component('Toggle', Toggle);

export default {
  validationNameStrategy: ['validationName', 'label', 'name', 'type'],
  library: {
    button: {
      classification: 'button',
      component: Button,
    },
    submit: {
      classification: 'button',
      component: Button,
    },
    date: {
      classification: 'date',
      component: Date,
    },
    calendar: {
      classification: 'calendar',
      component: Datepicker,
      slotProps: {
        component: ['now'],
      },
    },
    toggle: {
      classification: 'checkbox',
      component: Toggle,
    },
  },
  plugins: [nl],
  classes: {
    input(context) {
      if (context.classification === 'box' && context.attrs.appearance === 'pills') {
        return 'invisible pointer-events-none absolute';
      }

      switch (context.classification) {
        case 'button':
          return 'btn-primary flex';
        case 'box':
          return 'pointer-events-none invisible absolute';
        case 'group':
          return '';
        case 'file':
          return '';
        case 'select':
          return 'transition group-focus-within:border-primary block w-full py-2.5 px-4 text-gray-800 appearance-none border border-tertiary rounded-lg focus:outline-none placeholder:text-lg tracking-wider focus:border-primary bg-[url(~/assets/img/select-arrow.svg)] bg-no-repeat bg-origin-content bg-right';
        default:
          return 'transition group-focus-within:border-primary block w-full py-2.5 px-4 text-gray-800 appearance-none border border-tertiary rounded-lg focus:outline-none placeholder:text-lg tracking-wider focus:border-primary';
      }
    },
    element({classification}) {
      switch (classification) {
        case 'box':
          return 'px-1';
        default:
          return 'w-full';
      }
    },
    outer(context) {
      if (context.isGrouped) {
        return '';
      } else {
        switch (context.classification) {
          case 'button':
            return '';
          case 'radio':
            return '';
          default:
            return 'relative group';
        }
      }
    },
    wrapper(context) {
      if (context.classification === 'box' && context.attrs.appearance === 'pills') {
        return '';
      }

      return {
        box: 'w-full flex justify-start',
      }[context.classification] ?? '';
    },
    label(context) {
      const classes = [];

      if (context.classification === 'box' && context.attrs.appearance === 'pills') {
        classes.push('cursor-pointer block list-none border border-secondary-300 py-3 rounded-full font-bold text-center transition hover:scale-[1.05] text-lg');

        if (context.hasValue) {
          classes.push('bg-secondary-300 text-white');
        } else {
          classes.push('text-tertiary-100');
        }
      } else if (context.classification === 'box' || context.classification === 'file') {
        classes.push('font-body flex-grow text-left text-sm text-tertiary-100 checkbox-label');
      } else {
        classes.push('group-focus-within:text-xs group-focus-within:text-primary group-focus-within:top-0 group-focus-within:-translate-y-1/2 group-focus-within:font-bold group-focus-within:px-1 pointer-events-none block absolute left-4 transition-all bg-white whitespace-nowrap text-ellipsis max-w-[80%] overflow-hidden');
        classes.push(context.classification === 'date' ? 'z-10' : '');
        classes.push(
          context.hasValue
            ? 'text-xs top-0 -translate-y-1/2 font-bold text-tertiary-200 px-1'
            : 'top-3 md:top-2.5 md:top-2 text-sm sm:text-base md:text-lg text-tertiary-100',
        );
      }

      return classes.join(' ');
    },
    decorator: (context) => {
      if (context.attrs.appearance !== 'pills') {
        let base = ' cursor-pointer border rounded border-secondary-300 focus:border-primary-light inline-block w-4 h-4 mr-2';
        if (context.hasValue) {
          base += ' bg-secondary bg-[url(~/assets/img/check-white.svg)] bg-center bg-no-repeat';
        }
        return base;
      }
    },
    help: 'text-xs mt-3 my-1 text-gray-dark',
    error: 'text-error text-burningOrange text-xs my-1',
    uploadArea: 'upload-area p-5 mt-2',
    file: ' appearance-none rounded border p-3 block flex relative items-center justify-center',
    files: 'hidden',
    uploadAreaMask({attrs: {loading}}) {
      const base = 'absolute pointer-events-none flex justify-center items-center inset-0 border-2 border-dashed border-gray rounded upload-placeholder';

      if (loading) {
        return `${base} loading`;
      } else {
        return base;
      }
    },
  },
  errorHandler,
};
