
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'getFromSettings',
    ]),
    android() {
      return this.getFromSettings('android');
    },
    ios() {
      return this.getFromSettings('ios');
    },
  },
};
