import {set} from 'vue';
import {Capacitor} from '@capacitor/core';
import {Preferences} from '@capacitor/preferences';
import {BaseScheme, Storage} from '@nuxtjs/auth-next/dist/runtime';
import Token from '~/plugins/token';

import meQuery from '~/graphql/queries/sofie/me.gql';

import loginMutation from '~/graphql/mutations/auth/login.gql';
import logoutMutation from '~/graphql/mutations/auth/logout.gql';

const DEFAULTS = {
  name: 'apollo',
  token: {
    property: 'token',
    type: 'Bearer',
    name: 'Authorization',
    maxAge: 1800,
    global: true,
    required: true,
    prefix: '_token.',
    expirationPrefix: '_token_expiration.',
  },
  user: {
    property: 'user',
    autoFetch: true,
  },
};

function routeOption(route, key, value) {
  return route.matched.some((m) => {
    if (process.client) {
      return Object.values(m.components).some(component => component.options && component.options[key] === value);
    } else {
      return Object.values(m.components).some(component => Object.values(component._Ctor).some(ctor => ctor.options && ctor.options[key] === value));
    }
  });
}

export default class ApolloScheme extends BaseScheme {
  constructor(auth, options) {
    const opts = {...DEFAULTS, ...options};
    const isNativePlatform = process.client ? Capacitor.isNativePlatform() : false;

    super(auth, opts);

    if (isNativePlatform) {
      auth.$storage = new NativeStorage(auth.ctx, auth.$storage);
      auth.setUser = this.customSetUser;
      auth.init = this.customInit;
      auth.setUserToken = this.customSetUserToken;

      this.mounted = this._nativeMounted;
    }

    this.$auth = auth;
    this.$apollo = auth.ctx.app.apolloProvider.defaultClient;
    this.token = new Token(this, this.$auth.$storage);
    this._name = options.name;
    this._apolloCache = auth.ctx.app.apolloProvider.defaultClient.cache;

    this.check = isNativePlatform ? this._nativeCheck : this._browserCheck;
  }

  get apolloTokenName() {
    return `${this.$auth.options.cookie.prefix}_token.${this._name}`;
  }

  async customInit() {
    if (this.options.resetOnError) {
      this.onError((...args) => {
        if (typeof this.options.resetOnError !== 'function' || this.options.resetOnError(...args)) {
          this.reset();
        }
      });
    }
    await this.$storage.syncUniversal('strategy', this.options.defaultStrategy);
    const strategy = await this.getStrategy(false);
    if (!strategy) {
      await this.$storage.setUniversal('strategy', this.options.defaultStrategy);
      const strategy = await this.getStrategy(false);
      if (!strategy) {
        return Promise.resolve();
      }
    }
    try {
      await this.mounted();
    } catch (error) {
      this.callOnError(error);
    } finally {
      if (process.client && this.options.watchLoggedIn) {
        this.$storage.watchState('loggedIn', (loggedIn) => {
          if (!routeOption(this.ctx.route, 'auth', false)) {
            this.redirect(loggedIn ? 'home' : 'logout');
          }
        });
      }
    }
  }

  async customSetUser(user) {
    await this.$storage.setState('user', user);
    let check = {valid: Boolean(user)};
    if (check.valid) {
      check = (await this.check());
    }
    this.$storage.setState('loggedIn', check.valid);
  }

  customSetUserToken(token) {
    this.getStrategy().token.set(token);
  }

  async _nativeMounted() {
    await this.check(true);
  }

  async login({data}) {
    const {data: {login}} = await this.$apollo.mutate({
      mutation: loginMutation,
      variables: data,
    });

    await this.$auth.setUserToken(login);
    await this.fetchUser();
  }

  fetchUser(force) {
    if (!force && !this.$auth.check().valid) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(false);
    }

    return this.$apollo.query({query: meQuery, fetchPolicy: 'no-cache'})
      .then(({data: {me}}) => me).then(data => this.$auth.setUser(data));
  }

  async logout() {
    await this.reset();
  }

  async reset() {
    await this.$apollo.mutate({
      mutation: logoutMutation,
    });

    await this._apolloCache.reset();
    await this.$auth.setUserToken(null, null);
    await this.$auth.setUser(false);
  }

  async _nativeCheck(checkValidity = false) {
    const res = await this._checkNativeToken();
    if (!checkValidity) {
      return {
        valid: !!res,
      };
    }

    if (checkValidity && !!res) {
      await this.$auth.setUserToken(res);
      this.fetchUser(true);
      this.$auth.$storage.setState('loggedIn', true);
    }
  }

  _browserCheck() {
    const cookies = this.$auth.$storage.getCookies();
    return {
      valid: Boolean(cookies[this.apolloTokenName]),
    };
  }

  async _checkNativeToken() {
    const preference = await Preferences.get({key: this.apolloTokenName});

    return preference.value;
  }
}

class NativeStorage extends Storage {
  constructor(context, originalStorage) {
    super(context, originalStorage.options);
  }

  _initState() {
    set(this, '_state', {});
    this._useVuex = this.options.vuex && !!this.ctx.store;

    if (this._useVuex) {
      this.state = this._resolveState();
    }
  }

  _resolveState() {
    const state = this.ctx.store.state[this.options.vuex.namespace];
    if (state) {
      return state;
    }

    super._initState();
    this._resolveState();
  }

  async removeUniversal(key) {
    this.removeState(key);
    await Preferences.remove({key});
  }

  async setUniversal(key, value) {
    if (!value) {
      return this.removeUniversal(key);
    }

    if (key === 'undefinedapollo') {
      throw new Error('some error here');
    }

    await Preferences.set({key, value});
    this.setState(key, value);

    return value;
  }

  async syncUniversal(key, defaultValue) {
    let value = await this.getUniversal(key);
    if (!value && !!value) {
      value = defaultValue;
    }
    if (!value) {
      this.setUniversal(key, value);
    }
    return value;
  }

  async getUniversal(key) {
    const preferenceValue = (await Preferences.get({key}))?.value;

    if (!preferenceValue) {
      return this.getState(key);
    }

    return preferenceValue;
  }
}
