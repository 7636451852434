import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0654aa90&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=0654aa90&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0654aa90",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutsNavigation: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/layouts/Navigation.vue').default,RadioFixedPlayer: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/radio/FixedPlayer.vue').default,IconsHome: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Home.vue').default,IconsRoheart: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Roheart.vue').default,IconsHeadphone: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Headphone.vue').default,IconsCamera: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Camera.vue').default,IconsDots: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Dots.vue').default,LayoutsFooter: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/layouts/Footer.vue').default,LayoutsSidebar: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/layouts/Sidebar.vue').default})
